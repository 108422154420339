import React from 'react'
import styled from 'styled-components'
import { Text, Svg as SvgComponent, UserInterface } from '..'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import {
  ICheckList,
  ICheckListContainer,
  ISvgStyled,
  ITextStyled,
} from './type'
import { ETextAlign } from '../type'

/**
 * Style
 */
const CheckListContainer = styled.div<ICheckListContainer>`
  width: fit-content;
  margin: ${props => (props.$center ? 'auto' : '0')};
`

const Check = styled.div`
  position: absolute;
  left: 0;
  top: 2px;
`
const ItemStyled = styled.li`
  position: relative;
  margin: 20px;
`
const TextStyled = styled(({ children, ...props }: ITextStyled) => (
  <Text {...props}>{children}</Text>
))`
  margin-left: 36px;
  color: ${({ color }) =>
    color ? UserInterface.colors[color] : 'initial'} !important;

  .slick-current & {
    color: ${UserInterface.colors.middleGrey} !important;
  }
`

const Svg = styled(({ ...props }: ISvgStyled) => <SvgComponent {...props} />)`
  color: ${({ color }) =>
    color ? UserInterface.colors[color] : 'initial'} !important;
`

/**
 * Component
 */

const CheckList: React.FC<ICheckList> = ({
  color = 'middleGrey',
  checkColor = 'turquoise',
  align = ETextAlign.LEFT,
  content,
  className,
  center = false,
}) => (
  <CheckListContainer $center={center}>
    <ul className={classNames(className)}>
      {content.map(field => {
        const itemUuid = uuid()
        return (
          <ItemStyled key={itemUuid} className="check-list__item">
            <Check>
              <Svg
                src="components/check-list/check-circle-solid"
                width="1rem"
                color={checkColor}
              />
            </Check>
            <TextStyled color={color} align={align}>
              {field}
            </TextStyled>
          </ItemStyled>
        )
      })}
    </ul>
  </CheckListContainer>
)

export default CheckList
