import React, { Children } from 'react'
import styled from 'styled-components'
import { UserInterface } from '..'
import { v4 as uuid } from 'uuid'
import { IBulletList } from './type'

/**
 * Style
 */
const BulletListDot = styled(({ ...props }) => <span {...props} />)`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-top: 6px;
  background-color: ${({ color }) =>
    color === 'white'
      ? UserInterface.colors.white
      : UserInterface.colors.turquoise};
`
const BulletListSlideTextStyled = styled.div`
  flex: 1;
  margin-left: 1rem;
`
const BulletListSlideItemStyled = styled.li`
  margin: 20px;
  display: flex;
  align-items: flex-start;
`

/**
 * Component
 */
const BulletList: React.FC<IBulletList> = ({ datas, color }) => (
  <ul>
    {datas.map(data => {
      const itemUuid = uuid()
      return (
        <BulletListSlideItemStyled key={itemUuid}>
          <BulletListDot color={color} />
          <BulletListSlideTextStyled>
            {Children.map(data, component =>
              React.cloneElement(component, {
                color: color,
                align: 'left',
              })
            )}
          </BulletListSlideTextStyled>
        </BulletListSlideItemStyled>
      )
    })}
  </ul>
)
BulletList.displayName = 'BulletList' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default BulletList
